<template>
    <div class="relative w-full">
        <input
            :id="id"
            :value="inputValue"
            :type="type"
            :disabled="disabled"
            class="px-2.5 pb-2.5 pt-4 w-full text-sm md:text-base text-base-content bg-transparent disabled:bg-sp-base-100 rounded-[10px] border-2 border-base-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer disabled:text-base-300 disabled:cursor-not-allowed"
            :class="{'pl-10': $slots.left}"
            placeholder=" "
            @input="onChange"
            @blur="onBlur" />
        <label
            :for="id"
            class="absolute text-sm md:text-base text-info-200 duration-300 transform -translate-y-4 scale-75 top-1.5 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-2 pointer-events-none peer-placeholder-shown:pointer-events-auto"
            :class="{'pl-8': $slots.left && !inputValue}">
            {{ placeholder }}
            <span v-if="required" :class="{'text-error': inputValue}"> * </span>
        </label>
        <span v-if="$slots.left" class="absolute left-3 inset-y-0 flex items-center cursor-pointer z-10">
            <slot name="left"></slot>
        </span>
        <span v-if="$slots.right" class="absolute right-3 inset-y-0 flex items-center cursor-pointer">
            <slot name="right"></slot>
        </span>
    </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import {useField} from 'vee-validate';

export interface InputProps {
    type?: string;
    disabled?: boolean;
    required?: boolean;
    id: string;
    name: string;
    placeholder?: string;
    pattern?: string;
    minlength?: number;
}

const props = withDefaults(defineProps<InputProps>(), {
    type: 'text',
    disabled: false,
    required: false,
    placeholder: undefined,
    pattern: undefined,
    minlength: undefined,
});

const emit = defineEmits(['blur']);

const isFocused = ref(false);

const {value: inputValue, handleChange, handleBlur} = useField<string>(() => props.name);

const onChange = (event: Event) => {
    handleChange(event, false);
};

const onBlur = (event: Event) => {
    handleBlur(event, true);
    isFocused.value = false;
    emit('blur');
};
</script>
